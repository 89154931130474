const colors = [
    'sand',
    'blue',
    'red',
    'dark',
    'green',
    'sand',
    'blue',
    'red',
    'dark',
    'green',
]

export { colors }
